import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'

export const slice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {},
})

export const {} = slice.actions

export const selectCurrentOrg = (state) => state.session?.current_org
export const selectSession = (state) => state.session
export const selectCurrentUserId = (state) =>
  get(state, 'session.current_user.id', null)

export const selectPaymentOption = (state) =>
  get(state, 'session.current_org.payment_option', 'credit_card')

export default slice.reducer
