import React from 'react'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import { Row, Col, Tabs } from 'antd'
import I18n from 'i18n-js/index.js.erb'

import configureStore from '../../jslibs/store'
import { getUrlSlug } from '../../jslibs/utils'
import NavBar from '../../uxlibs/Navbar'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'

const { TabPane } = Tabs

const App = ({ session = {} }) => {
  return (
    <Provider store={configureStore({}, { session })}>
      <NavBar />
      <Row justify="center" className="login-box-wrapper">
        <Col lg={8} className={'login-box'}>
          <Tabs
            activeKey={getUrlSlug()}
            onChange={(key) => (window.location.href = `/users/${key}`)}
          >
            <TabPane tab={I18n.t('sign_in_label')} key="sign_in">
              <SignIn />
            </TabPane>
            <TabPane disabled tab={I18n.t('sign_up_label')} key="sign_up">
              <SignUp />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Provider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const g = document.createElement('div')
  g.setAttribute('id', 'sessions')
  render(
    <App session={window.spa_session_data || {}} />,
    document.getElementById('page-content').appendChild(g)
  )
})
