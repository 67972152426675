import merge from 'lodash/merge'
import { combineReducers } from '@reduxjs/toolkit'
import { configureStore as _configureStore } from '@reduxjs/toolkit'

import session from './sessionSlice'
import notifications from './notificationsSlice'
import stripe from './stripeSlice'

function customLogger({ getState }) {
  return (next) => (action) => {
    console.log('\nDev - Dispatching:')
    console.log(action)

    const returnValue = next(action)

    console.log('Resultant state:')
    console.log(getState())
    console.log('\n')

    return returnValue
  }
}

const monitorReducerEnhancer =
  (createStore) => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
      const start = performance.now()
      const newState = reducer(state, action)
      const end = performance.now()
      const diff = Math.round((end - start) * 100) / 100

      console.log('Reduce time:', diff)

      return newState
    }

    return createStore(monitoredReducer, initialState, enhancer)
  }

export default function configureStore(reducers = {}, preloadedState = {}) {
  const reducer = combineReducers(
    merge(
      {
        session,
        notifications,
        stripe,
      },
      reducers
    )
  )

  const storeConfig = {
    reducer,
    preloadedState,
  }

  if (process.env.NODE_ENV === 'development') {
    storeConfig.middleware = (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(customLogger)
    storeConfig.enhancers = [monitorReducerEnhancer]
  }

  const store = _configureStore(storeConfig)

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(reducer))
  }

  return store
}
